import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import * as Yup from 'yup';
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm/ContactForm'
import SEO from '../components/SEO'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

const DrawMap = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={10}
    defaultCenter={{ lat: 51.5911399, lng: 0.6103775 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: 51.5911399, lng: 0.6103775 }} />}
  </GoogleMap>
))

const requiredError = 'Please fill in the required field.';
const _formData = {
  formName: 'acscotland-contact',
  recaptcha: {
    display: true,
    theme: 'light'
  },
  privacyPolicy: {
    display: true
  },
  validationSchema: Yup.object().shape({
    Name: Yup.string()
      .required(requiredError),
    Email: Yup.string()
      .email()
      .required(requiredError),
    Telephone: Yup.string()
      .required(requiredError),
    Enquiry: Yup.string()
      .required(requiredError),
    recaptcha: Yup.string()
      .required('Recaptcha response is required.'),
    privacyPolicy: Yup.boolean()
      .oneOf([true], 'Privacy Policy agreement is required.')
      .required('Privacy Policy agreement is required.')
  }),
  formFields: [
    {
      name: 'Name',
      label: 'Name',
      placeholder: 'Your name',
      type: 'text'
    },
    {
      name: 'Email',
      label: 'Email Address',
      placeholder: 'Your email',
      type: 'text'
    },
    {
      name: 'Telephone',
      label: 'Telephone',
      placeholder: 'Your telephone',
      type: 'text'
    },
    {
      name: 'Enquiry',
      label: 'Enquiry',
      placeholder: 'Your message',
      type: 'textarea'
    }
  ],
  initialValues: {
    Name: '',
    Email: '',
    Telephone: '',
    Enquiry: ''
  }
};

export const PageTemplate = ({ title, content }) => {
  return (
    <section className="page-wrapper">
      <DrawMap
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmKOimZlJmP8ZRCW5ZfpJaHyJdx0-2G-g&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `480px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
      <div className="container">
        <div className="columns">
          <div className='column is-10 is-offset-1 section'>
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              {title}
            </h2>
          </div>
        </div>
        <div className='columns'>
          <div className="column is-5 is-offset-1">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
          <div className='column is-4 is-offset-1'>
            <ContactForm formData={_formData} />
          </div>
        </div>
      </div>
    </section>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data

  return (
    <Layout>
      <SEO
        title={page.title}
      />
      <PageTemplate
        title={page.title}
        content={page.content}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query ContactPageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
    }
  }
`
